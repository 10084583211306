<div class="copyright">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem">
        
        <p style="color:whitesmoke">© Eurocontrol Recovery. Todos los derechos reservados.</p>
        
    </div>


</div>
