<nav fxLayout="row"
     fxLayoutAlign="space-between center"
     fxLayoutGap="2rem">

    <a routerLink="home" class="logo" fxFlex="15">
        <img src="../../../../../assets/img/logos/logo_positivo.png" alt="logo" style="width:200px">
    </a>
    
    
    <div fxFlex=75
         fxLayout="row" 
         fxLayoutAlign="space-evenly center"
         fxLayoutGap="1rem"
         *ngIf="!mobile">
        
        <a *ngFor="let item of links">
            <div class="menu-item" *ngIf="item.url==='home'">
                {{item.title}}
            </div>
            
        </a>
    
    </div>
    
    <div *ngIf="mobile" fxFlex="60"></div>
    
    <div fxFlex=10
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="2rem">
        
        <a routerLink="acceso" *ngIf="!mobile" class="clientes">
            <button mat-raised-button color="success">
                Área Clientes
            </button>
        </a>

        <div *ngIf="mobile" (click)="openDialog()">
            <i class="menu-icon fa  fa-bars fa-2x" aria-hidden="true"></i>
        </div>
    </div>
    
    
    

</nav>
