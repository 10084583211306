import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'footer-legal-links',
  templateUrl: './footer-legal-links.component.html',
  styleUrls: ['./footer-legal-links.component.css']
})
export class FooterLegalLinksComponent implements OnInit {

  
    
  footerLinks = [
      {
          title : "Política de Privacidad",
          page : "politica-privacidad",
          hidden : false
      },
      {
          title : "Aviso Legal",
          page : "aviso-legal",
          hidden : false
      },
      {
          title : "Política de Cookies",
          page : "cookies",
          hidden : true
      },
      {
          title : "Contacto",
          page : "contact",
          hidden : true
      }
      
  ]
    
  constructor() { }

  ngOnInit(): void {}

}
