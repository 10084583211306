import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module'

import { LayoutComponent } from './layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuDialog } from './header/menu-old/menu.component';

import { FooterLegalLinksComponent } from './footer/footer-legal-links/footer-legal-links.component';
import { CopyrightComponent } from './footer/copyright/copyright.component';
import { FooterLocationComponent } from './footer/footer-location/footer-location.component';
import { FooterLogoComponent } from './footer/footer-logo/footer-logo.component';
import { MenuDialogComponent } from './header/menu-dialog/menu-dialog.component';


@NgModule({
  declarations: [
      LayoutComponent,
      FooterComponent,
      HeaderComponent,
      FooterLegalLinksComponent,
      CopyrightComponent,
      FooterLocationComponent,
      FooterLogoComponent,
      MenuDialogComponent,
      MenuDialog
  ],
  imports: [
    SharedModule
  ],
    exports : [
        LayoutComponent
    ],
    providers: []
})
export class LayoutModule { }
