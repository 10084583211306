<div fxLayout="column"
     fxLayoutAlign="center center">
    
    
    <div fxLayout="row"
         fxLayoutAlign="end center"
         style="width:100%">
        
        <button mat-button (click)="onCloseClick()">
            Close</button>
    
    </div>
    
    
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutAlign="center center"
         style="margin-top: 5rem;">
        
            <h1 *ngFor="let value of data;index as i"
                (click)="onItemSelected(i)"
                class="title">
                {{value.title}}
            </h1>
    </div>

    

</div>