<mat-card class="city-card">
    
    <mat-card-content class="city_background">
        
        <div class="city">

            <a href="" target="_blank" rel="nofollow noopener">
                <p class="city_name">MADRID</p>
            </a>
        </div>
        
    </mat-card-content>
    
    
</mat-card>
