import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuDialog } from './menu-old/menu.component'
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  
    constructor(public dialog: MatDialog,
                private router : Router) { }

    mobile = false;

    ngOnInit(): void {
        if (window.screen.width <= 962) { 
            this.mobile = true;
            
        }
    }
    
    onClick(event){
        console.log(event)
    }
    
    links = [
        {
            title : 'Nosotros',
            url : 'home',
            id : 'nosotros'
            
        },
        {
            title : 'Servicios',
            url : 'home',
            id : 'servicios'
        },
        {
            title : 'Guía de cobro',
            url : 'home',
            id : 'guia'
        },
        {
            title : 'Contacto',
            url : 'home',
            id : 'contacto'
        },
        {
            title : 'Área Clientes',
            url : '/acceso'
        }
    ]
    
    openDialog(): void {
        let dialogRef = this.dialog.open(MenuDialog, {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            hasBackdrop: false,
            panelClass : "menu-container",
            data : this.links
        });
        
        dialogRef.afterClosed().subscribe (result =>{
            
            let url = this.links[result.event].url
            this.router.navigateByUrl(url)
            
        })


  }


    

}
