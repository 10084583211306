import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path:'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    },
    {
        path:'acceso',
        loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule),
    },
    {
        path:'aviso-legal',
        loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
    },
    {
        path:'politica-privacidad',
        loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
