import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: "app-menu",
    templateUrl: "menu.component.html",
    styleUrls: ["menu.component.css"]
})

export class MenuDialog {
  
    constructor(
    
    public dialogRef: MatDialogRef<MenuDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  
    onCloseClick(): void {
        this.dialogRef.close();
    }
    
    onItemSelected(i){
       this.dialogRef.close({event:i});
    }
    
    

}

