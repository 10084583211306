<div fxLayout="column"
     fxLayoutAlign="center center"
     class="footer-text">

     <div>
         <img src="../../../../../assets/img/logos/logo_negativo.png" alt="logo" style="width:200px">
     </div>


</div>
