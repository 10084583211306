

<div fxLayout="column" fxFlexFill>
    <app-header></app-header>
    
    <div fxFlex>
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>

</div>

