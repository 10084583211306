<footer >
    <div fxLayout="row"
         fxLayoutAlign="space-between end"
         fxLayout.lt-md="column"
         fxLayoutAlign.lt-md="end end"
         fxLayoutGap.lt-md="0">
        
        <div class="bg-footer"
             style="width:100%"
             fxFlex="80"
             fxFlex.lt-md="100">
            
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayout.lt-md="column"
                 fxLayoutAlign.lt-md="space-between center">
                
                <div fxFlex="45" fxFlex.lt-md="100">
                    <app-footer-logo></app-footer-logo>
                </div>
                
                <div fxFlex="45" fxFlex.lt-md="100">
                    <footer-legal-links></footer-legal-links>
                </div>
            </div>
        
        </div>
        
        <div fxFlex="20"
             fxFlex.lt-md="100"
             style="width:100%;height:100%">
            <app-footer-location></app-footer-location>
        </div>
    
    
    </div>
    
    <app-copyright></app-copyright>

</footer>




