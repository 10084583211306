<div fxLayout="column"
     fxLayoutAlign="space-between end"
     fxLayoutAlign.sm="center center"
     fxLayoutAlign.xs="center center"
     style="margin-top:1rem;">

     <ul style="list-style-type: none">
        <li *ngFor="let link of footerLinks" style="margin-bottom: 0.5rem"> 
            <a class="nav-footer-links"
               [routerLink]="link.page"
               routerLinkActive="active"
               [hidden]="link.hidden">
                {{link.title}}
            </a>    
                
        </li>

    </ul>
            
</div>



     
