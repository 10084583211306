import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-location',
  templateUrl: './footer-location.component.html',
  styleUrls: ['./footer-location.component.css']
})
export class FooterLocationComponent implements OnInit {

  
    
    constructor() { }

    ngOnInit(): void {

    }

}
