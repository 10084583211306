import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule }   from './material.module'
import { FormsModule } from '@angular/forms';

const SHARED_MODULES = [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule
];

const SHARED_COMPONENTS = [];

@NgModule({
    imports: [
        ...SHARED_MODULES,
    ],
    declarations: [
        ...SHARED_COMPONENTS
    ],
    exports: [
        ...SHARED_MODULES,
        ...SHARED_COMPONENTS
    ],
})
export class SharedModule { }